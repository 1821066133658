import { create } from 'zustand'
import { IFileData } from '../interfaces/IFileData'
import { CREATE_RECORD_FILES_CACHE_KEY } from '../constants'
import { IAttachment } from '../interfaces/IAttachment'

interface INewRecordStore {
  message: string
  setMessage: (message: string) => void

  attachments: IFileData[]
  loadAttachmentsFromCache: () => void
  addAttachments: (filesToAdd: File[]) => void
  removeAttachment: (fileDataToRemove: IFileData) => void
  clearAttachments: () => void

  filesCurrentlyUploading: (IAttachment & IFileData)[]
  addUploadingFiles: (files: (IAttachment & IFileData)[]) => void
  removeUploadingFiles: (files: (IAttachment & IFileData)[]) => void
}

// TODO: This store should be reworked because currently it use same cache for sharing and for storing of "draft" attachments.
export const useNewRecordStore = create<INewRecordStore>((set) => ({
  message: '',
  attachments: [],
  filesCurrentlyUploading: [],

  setMessage: (message) => set({ message }),

  loadAttachmentsFromCache: async () => {
    const shareCache = await caches.open(CREATE_RECORD_FILES_CACHE_KEY)
    const keys = await shareCache.keys()
    const filesData = await Promise.all(
      keys.map(async (key) => {
        const fileName = key.url.split('/').at(-1)!
        const cachedFile = await shareCache.match(key)
        const blob = await cachedFile!.blob()

        return {
          fileName,
          blob,
        }
      })
    )

    set({ attachments: filesData })
  },
  addAttachments: async (filesToAdd) => {
    // TODO: Probably it make more sense to generate file uuid-name here, but then it should be generated somehow during editing attachments.
    const shareCache = await caches.open(CREATE_RECORD_FILES_CACHE_KEY)
    const filesDataToAdd = await Promise.all(
      filesToAdd.map(async (fileToAdd) => {
        await shareCache.put(fileToAdd.name, new Response(fileToAdd))

        return {
          fileName: fileToAdd.name,
          blob: fileToAdd,
        }
      })
    )
    set((state) => ({ attachments: [...(state.attachments || []), ...filesDataToAdd] }))
  },
  removeAttachment: async (fileDataToRemove) => {
    const shareCache = await caches.open(CREATE_RECORD_FILES_CACHE_KEY)
    await shareCache.delete(fileDataToRemove.fileName)

    set((state) => ({
      attachments: (state.attachments || []).filter((fileData) => fileData !== fileDataToRemove),
    }))
  },
  clearAttachments: async () => {
    const keys = await caches.keys()
    const isCacheForFilesExits = keys.includes(CREATE_RECORD_FILES_CACHE_KEY)
    if (isCacheForFilesExits) {
      await caches.delete(CREATE_RECORD_FILES_CACHE_KEY)
    }
    set({ attachments: [] })
  },

  addUploadingFiles: (files: (IAttachment & IFileData)[]) => {
    set(({ filesCurrentlyUploading }) => ({
      filesCurrentlyUploading: [...filesCurrentlyUploading, ...files],
    }))
  },
  removeUploadingFiles: (files: (IAttachment & IFileData)[]) => {
    set(({ filesCurrentlyUploading }) => ({
      filesCurrentlyUploading: filesCurrentlyUploading.filter((file) => !files.includes(file)),
    }))
  },
}))
