import { create } from 'zustand'

interface IAppStore {
  isBootstrapped: boolean
  setBootstrapped: () => void

  isLoading: boolean
  setIsLoading: (newStatus: boolean) => void

  isOffline: boolean

  isMenuOpened: boolean
  toggleMenu: () => void

  openedMenuRecordId: string | null
  setRecordMenuId: (id: string | null) => void

  editingRecordId: string | null
  setRecordId: (id: string | null) => void
  stopEditing: () => void
}

export const useAppStore = create<IAppStore>((set) => ({
  isBootstrapped: false,
  setBootstrapped: () => set({ isBootstrapped: true }),

  isLoading: true,
  setIsLoading: (newLoadingState) => set({ isLoading: newLoadingState }),

  isOffline: false,

  isMenuOpened: false,
  toggleMenu: () => set((state) => ({ isMenuOpened: !state.isMenuOpened })),

  openedMenuRecordId: null,
  setRecordMenuId: (id) => set({ openedMenuRecordId: id }),

  editingRecordId: null,
  setRecordId: (id) => set({ editingRecordId: id }),
  stopEditing: () => set({ editingRecordId: null }),
}))

window.addEventListener(
  'click',
  () => {
    if (useAppStore.getState().isMenuOpened) {
      useAppStore.setState({ isMenuOpened: false })
    }

    const { openedMenuRecordId: recordMenuId } = useAppStore.getState()
    if (recordMenuId) {
      setTimeout(() => {
        // NOTE: timeout allow button click handler to see that the menu is opened and state will be changed here.
        if (recordMenuId !== useAppStore.getState().openedMenuRecordId) return

        useAppStore.setState({ openedMenuRecordId: null })
      })
    }
  },
  true
)

window.addEventListener('offline', () => useAppStore.setState({ isOffline: true }))
window.addEventListener('online', () => useAppStore.setState({ isOffline: false }))
