import { useCallback } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { IRecord } from '../../../interfaces/IRecord'
import s from './Record.module.scss'
import { RecordMenu } from './RecordMenu/RecordMenu'
import { useAppStore } from '../../../stores/appStore'
import { EditRecord } from '../../EditRecord/EditRecord'
import { Attachments } from '../../Attachments/Attachments'

interface IRecordProps {
  record: IRecord
}

export const Record = ({ record }: IRecordProps) => {
  const isMenuVisible = useAppStore(
    (state) => Boolean(state.openedMenuRecordId) && state.openedMenuRecordId === record.id
  )
  const setRecordMenuId = useAppStore((state) => state.setRecordMenuId)

  const isEditing = useAppStore((state) => state.editingRecordId === record.id)
  const stopEditing = useAppStore((state) => state.stopEditing)

  const toggleMenuVisibility = useCallback(() => {
    if (!isMenuVisible) {
      // NOTE: Changing of state to "closed" handled by global 'click' event listener.
      setRecordMenuId(isMenuVisible ? null : record.id)
    }
  }, [isMenuVisible, record.id, setRecordMenuId])

  if (isEditing) {
    return (
      <EditRecord
        message={record.message}
        id={record.id}
        onSaved={stopEditing}
        onCancel={stopEditing}
        attachments={record.attachments || []}
      />
    )
  }

  return (
    <div
      key={record.id}
      className={classNames(s.record, { [s.active]: isMenuVisible }, 'recordWrapper')}
    >
      {false && <div className={s.header}>I am record header</div>}

      <div className={s.body}>
        <div className={s.recordContent}>
          <div className={s.message}>{record.message}</div>
          {Boolean(record.attachments?.length) && <Attachments filesData={record.attachments} />}
        </div>

        {/* TODO: Extract control block into separate component to simplify changes detection. */}
        <div className={s.recordControl}>
          <button
            type='button'
            aria-label='Toggle menu'
            className={s.menuToggler}
            onClick={toggleMenuVisibility}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </button>

          <div className={s.menuAnchor}>{isMenuVisible && <RecordMenu recordId={record.id} />}</div>
        </div>
      </div>
    </div>
  )
}
