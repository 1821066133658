import { useEffect, useRef, useState } from 'react'
import { onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useUserStore } from '../stores/userStore'
import { IRecord, IRecordData } from '../interfaces/IRecord'
import { getRecordsCollection } from '../shortcuts/getRecordsCollection'

export const useRecords = () => {
  const uid = useUserStore((state) => state.user?.uid)
  const [records, setRecords] = useState<IRecord[]>([])
  const recordsRef = useRef<IRecord[]>([])

  useEffect(() => {
    const q = query(getRecordsCollection(), where('owner', '==', uid), orderBy('createdTimestamp'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list: IRecord[] = [...recordsRef.current]
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (process.env.NODE_ENV === 'development') {
            const changedIndex = list.findIndex((it) => it.id === change.doc.id)
            if (changedIndex === -1) {
              list.push({
                id: change.doc.id,
                ...(change.doc.data() as IRecordData),
              })
            } else {
              list[changedIndex] = {
                id: change.doc.id,
                ...(change.doc.data() as IRecordData),
              }
            }
          } else {
            list.push({
              id: change.doc.id,
              ...(change.doc.data() as IRecordData),
            })
          }
        }
        if (change.type === 'modified') {
          const changedIndex = list.findIndex((it) => it.id === change.doc.id)
          list[changedIndex] = {
            id: change.doc.id,
            ...(change.doc.data() as IRecordData),
          }
        }
        if (change.type === 'removed') {
          const removedIndex = list.findIndex((it) => it.id === change.doc.id)
          list.splice(removedIndex, 1)
        }
      })
      recordsRef.current = list
      setRecords(list)
    })

    return unsubscribe
  }, [uid])

  return records
}
