import { FieldValue, addDoc, serverTimestamp } from 'firebase/firestore'
import { IRecordAddData } from '../interfaces/IRecord'
import { getRecordsCollection } from './getRecordsCollection'
import { useUserStore } from '../stores/userStore'
import { IAttachment } from '../interfaces/IAttachment'

// NOTE: Somehow the TS warns when used interface instead of type (with the same definition of fields).
type TRecordDataToAdd = {
  message: string
  attachments: IAttachment[]
  owner: string
  createdTimestamp: FieldValue
  updatedTimestamp: FieldValue
}

export const addRecord = (data: IRecordAddData) => {
  const recordDataToAdd: TRecordDataToAdd = {
    message: data.message || '',
    attachments: data.attachments || [],
    owner: useUserStore.getState().user!.uid,
    createdTimestamp: serverTimestamp(),
    updatedTimestamp: serverTimestamp(),
  }

  return addDoc(getRecordsCollection(), recordDataToAdd)
}
