import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import s from './DropDownMenu.module.scss'
import { ToggleMenuItem } from './ToggleMenuItem'
import { SignItem } from './SignItem'
import { useAppStore } from '../../stores/appStore'

export const DropDownMenu = () => {
  const isOpen = useAppStore((state) => state.isMenuOpened)
  const toggleMenu = useAppStore((state) => state.toggleMenu)
  const navigate = useNavigate()

  // TODO: Rework to link
  const goHome = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <div className={classNames(s.anchor, { [s.open]: isOpen })}>
      <div className={s.container}>
        {isOpen && <ToggleMenuItem isOpen={isOpen} />}

        <div className={s.item}>
          <button type='button' aria-label='About' className='actionBtn' onClick={goHome}>
            <FontAwesomeIcon icon={faHouse} />
          </button>
        </div>

        <SignItem />

        {!isOpen && <ToggleMenuItem toggleMenu={toggleMenu} isOpen={isOpen} />}
      </div>
    </div>
  )
}
