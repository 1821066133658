import { useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import s from './RecordComposer.module.scss'
import { noopFn } from '../../utils/noopFn'
import { useAutoHeightTextarea } from '../../hooks/useAutoHeightTextarea'
import { useAutoFocus } from '../../hooks/useAutoFocus'
import { IFileData } from '../../interfaces/IFileData'
import { Attachments } from '../Attachments/Attachments'
import { IAttachment } from '../../interfaces/IAttachment'

interface ITextAreaWithBtnProps {
  message: string
  onChange: (message: string) => void
  onSave: () => void
  onFilesAdded: (files: File[]) => void
  attachments: (IFileData | IAttachment)[]
  removeAttachment: (fileData: IFileData | IAttachment) => void
}

export const RecordComposer = ({
  message,
  onChange,
  onSave,
  onFilesAdded,
  attachments,
  removeAttachment,
}: ITextAreaWithBtnProps) => {
  const textInputRef = useRef<HTMLTextAreaElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const updateHeight = useAutoHeightTextarea(textInputRef)
  useAutoFocus(textInputRef)

  const onTextChanged = useCallback(() => {
    if (!textInputRef.current) return

    updateHeight()

    onChange(textInputRef.current.value.trim())
  }, [onChange, updateHeight])

  useEffect(() => {
    if (!textInputRef.current) return

    textInputRef.current.value = message
    updateHeight()
  }, [message, updateHeight])

  const onAddFile = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onFileChange = useCallback(() => {
    if (!fileInputRef.current!.files?.length) return

    onFilesAdded(Array.from(fileInputRef.current!.files))
  }, [onFilesAdded])

  return (
    <div className={classNames('recordWrapper', s.composerContainer)}>
      <div className={classNames(s.textInputContainer)}>
        <textarea
          ref={textInputRef}
          className={s.textInput}
          rows={1}
          placeholder='Type something here?'
          onInput={onTextChanged}
          defaultValue={message}
        />

        <input
          type='file'
          name='attachment'
          id='attachmentId'
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={onFileChange}
        />

        <div className={s.btnsContainer}>
          <button type='button' aria-label='Save record' className='actionBtn' onClick={onAddFile}>
            <FontAwesomeIcon icon={faPaperclip} />
          </button>

          <button
            type='button'
            aria-label='Save record'
            className='actionBtn'
            onClick={onSave}
            tabIndex={onSave === noopFn ? -1 : 0}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </button>
        </div>
      </div>

      {Boolean(attachments.length) && (
        <Attachments filesData={attachments} removeAttachment={removeAttachment} />
      )}
    </div>
  )
}
