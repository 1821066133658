export const firebaseConfig = {
  apiKey: 'AIzaSyDV1MasP1S5MUH85hOhpEGG_LzBqMXGWOk',
  authDomain:
    process.env.NODE_ENV === 'development' ? 'eugen-i.firebaseapp.com' : window.location.hostname,
  databaseURL: 'https://eugen-i.firebaseio.com',
  projectId: 'eugen-i',
  storageBucket: 'eugen-i.appspot.com',
  messagingSenderId: '651724908239',
  appId: '1:651724908239:web:01780320bebd01b18cb9ba',
}
