import s from './RecordList.module.scss'
import { Record } from './Record/Record'
import { useRecords } from '../../hooks/useRecords'

export const RecordList = () => {
  const records = useRecords()

  return (
    <div className={s.listContainer}>
      <div className={s.recordList}>
        {records.map((record) => (
          <Record key={record.id} record={record} />
        ))}
      </div>
    </div>
  )
}
