import { useCallback } from 'react'
import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { IRecordAddData, IRecordUpdateData, isRecordUpdatable } from '../interfaces/IRecord'
import { useAppStore } from '../stores/appStore'
import { useUserStore } from '../stores/userStore'
import { addRecord } from '../shortcuts/addRecord'
import { updateRecord } from '../shortcuts/updateRecord'
import { IFileData } from '../interfaces/IFileData'
import { IAttachment, isAttachment } from '../interfaces/IAttachment'
import { useNewRecordStore } from '../stores/newRecordStore'

export const useSaveRecord = () => {
  const setIsLoading = useAppStore((state) => state.setIsLoading)
  const uid = useUserStore((state) => state.user?.uid)
  const addUploadingFiles = useNewRecordStore((state) => state.addUploadingFiles)
  const removeUploadingFiles = useNewRecordStore((state) => state.removeUploadingFiles)

  if (!uid) {
    throw new Error('User not signed in.')
  }

  // TODO: Probably data for saving should be taken from store instead of from params.
  // But from another side such implementation can't be used in any place, for instance to save edited record.
  return useCallback(
    async (
      record: IRecordAddData | IRecordUpdateData,
      newAttachments: (IFileData | IAttachment)[], // TODO: Actually new files and saved will have different interfaces... need to think about it.
      oldAttachments: IAttachment[] = []
    ): Promise<void> => {
      setIsLoading(true)

      const storage = getStorage()
      await Promise.all(
        oldAttachments.map(async (attachment) => {
          if (newAttachments.includes(attachment)) return null

          const attachmentRef = ref(
            storage,
            `userSpace/${uid}/attachments/${attachment.storedName}`
          )
          return deleteObject(attachmentRef)
        })
      )

      const newFilesData: (IAttachment & IFileData)[] = []
      const updatedAttachments: IAttachment[] = await Promise.all(
        newAttachments.map(async (attachmentOrFileData) => {
          if (isAttachment(attachmentOrFileData)) return attachmentOrFileData

          const fileNameId = uuidv4()
          // TODO: Need to ensure that the file extension is fine, because for instance file can be without extension.
          const fileExtension = attachmentOrFileData.fileName.split('.').at(-1)
          const storedName = `${fileNameId}.${fileExtension}`

          const attachment: IAttachment = {
            originName: attachmentOrFileData.fileName,
            storedName,
            mimeType: attachmentOrFileData.blob.type,
          }
          newFilesData.push({
            ...attachmentOrFileData,
            ...attachment,
          })

          return attachment
        })
      )

      const recordToSave = {
        ...record,
        attachments: updatedAttachments,
      }

      addUploadingFiles(newFilesData)

      const savingRecordPromise = isRecordUpdatable(recordToSave)
        ? updateRecord(recordToSave)
        : addRecord(recordToSave)

      await savingRecordPromise

      // TODO: Required proper handling of errors for all steps.
      try {
        await Promise.all(
          newFilesData.map((fileData) => {
            const fileRef = ref(storage, `userSpace/${uid}/attachments/${fileData.storedName}`)
            return uploadBytes(fileRef, fileData.blob)
          })
        )
      } finally {
        removeUploadingFiles(newFilesData)
      }

      setIsLoading(false)
    },
    [addUploadingFiles, removeUploadingFiles, setIsLoading, uid]
  )
}
