import { Header } from '../../Header/Header'
import { RecordList } from '../../RecordList/RecordList'
import { CreateRecord } from '../../CreateRecord/CreateRecord'

// eslint-disable-next-line arrow-body-style
export const RecordsPage = () => {
  return (
    <>
      <Header />

      <RecordList />

      <div className='horizontalLine' />

      <CreateRecord />
    </>
  )
}
