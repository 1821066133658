import { useCallback, useEffect, useState } from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import anyFile from './file.svg'
import fileLoading from './spinner-solid.svg'
import s from './Attachment.module.scss'
import { IAttachment, isAttachment } from '../../interfaces/IAttachment'
import { IFileData, isFileData } from '../../interfaces/IFileData'
import { useUserStore } from '../../stores/userStore'
import { noopFn } from '../../utils/noopFn'
import { useNewRecordStore } from '../../stores/newRecordStore'

interface IAttachmentProps {
  fileData: IFileData | IAttachment
  removeAttachment?: (fileData: IFileData | IAttachment) => void
}

const imageRegExp = /^image\//i

export const Attachment = ({ fileData, removeAttachment = noopFn }: IAttachmentProps) => {
  const [fileSrc, setFileSrc] = useState(fileLoading)
  const [fileUrl, setFileUrl] = useState('')
  const uid = useUserStore((state) => state.user?.uid)
  const isUploadingInProgress = useNewRecordStore(({ filesCurrentlyUploading }) => {
    if (isFileData(fileData)) {
      return false
    }

    return Boolean(filesCurrentlyUploading.find((file) => file.storedName === fileData.storedName))
  })

  useEffect(() => {
    if (isAttachment(fileData)) {
      if (isUploadingInProgress) {
        return
      }
      const storage = getStorage()
      const attachmentRef = ref(storage, `userSpace/${uid}/attachments/${fileData.storedName}`)
      getDownloadURL(attachmentRef).then((url) => {
        setFileUrl(url)
        setFileSrc(imageRegExp.test(fileData.mimeType) ? url : anyFile)
      })
      return
    }

    if (imageRegExp.test(fileData.blob.type)) {
      setFileSrc(URL.createObjectURL(fileData.blob))
      return
    }

    setFileSrc(anyFile)
  }, [fileData, isUploadingInProgress, uid])

  const onRemoveAttachment = useCallback(() => {
    removeAttachment(fileData)
  }, [removeAttachment, fileData])

  const fileName = isAttachment(fileData) ? fileData.originName : fileData.fileName

  return (
    <div className={s.fileContainer} title={fileName}>
      <div className={s.filePictureContainer}>
        <a href={fileUrl} target='_blank' rel='noreferrer' className={s.fileLink}>
          <img src={fileSrc} alt={fileName} className={s.filePicture} />
        </a>
      </div>

      {removeAttachment !== noopFn && (
        <div className={s.actionsContainer}>
          <div className={s.actionContainer}>
            <button
              type='button'
              aria-label='Remove attachment'
              className='actionBtn'
              onClick={onRemoveAttachment}
              tabIndex={0}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
