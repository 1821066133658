import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useRollbar } from '@rollbar/react'
import s from './EditRecord.module.scss'
import { IRecordUpdateData } from '../../interfaces/IRecord'
import { useSaveRecord } from '../../hooks/useSaveRecord'
import { RecordComposer } from '../RecordComposer/RecordComposer'
import { Info } from '../utils/Info/Info'
import { IconBtn } from '../utils/IconBtn/IconBtn'
import { IFileData } from '../../interfaces/IFileData'
import { IAttachment } from '../../interfaces/IAttachment'

const DEFAULT_ERROR_MESSAGE = 'Some error appears during saving of note.'

interface IEditRecordProps {
  id: string
  message: string
  attachments: IAttachment[]
  onSaved: () => void
  onCancel: () => void
}

export const EditRecord = ({
  id,
  message,
  attachments: savedAttachments,
  onSaved,
  onCancel,
}: IEditRecordProps) => {
  const rollbar = useRollbar()

  const [errorMessage, setErrorMessage] = useState('')
  const [recordMessage, setRecordMessage] = useState(message)
  const [attachments, setAttachments] = useState<(IFileData | IAttachment)[]>(savedAttachments)

  useEffect(() => {
    setRecordMessage(message)
  }, [message, setRecordMessage])

  const closeError = useCallback(() => {
    setErrorMessage('')
  }, [])

  const addAttachments = useCallback(
    (files: File[]) => {
      setAttachments([
        ...attachments,
        ...files.map((fileToAdd) => ({
          fileName: fileToAdd.name,
          blob: fileToAdd,
        })),
      ])
    },
    [attachments]
  )

  const removeAttachment = useCallback(
    (fileDataToRemove: IFileData | IAttachment) => {
      setAttachments(attachments.filter((fileData) => fileData !== fileDataToRemove))
    },
    [attachments]
  )

  const addRecord = useSaveRecord()
  const doSave = useCallback(() => {
    if (!recordMessage && !attachments.length) {
      setErrorMessage('Saving of empty record is forbidden.')
      return
    }

    if (errorMessage) {
      setErrorMessage('')
    }

    const newRecord: IRecordUpdateData = {
      id,
      message: recordMessage,
    }

    addRecord(newRecord, attachments, savedAttachments).then(
      () => {
        onSaved()
      },
      (err) => {
        rollbar.error(err)
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
      }
    )
  }, [recordMessage, attachments, errorMessage, id, addRecord, savedAttachments, onSaved, rollbar])

  return (
    <>
      {errorMessage && <Info message={errorMessage} onClose={closeError} type='error' isClosable />}

      <div className={s.editRecordContainer}>
        <div className={classNames('recordWrapper', s.editingHeader)}>
          <span>Editing</span>
          <IconBtn icon={faXmark} onClick={onCancel} aria-label='Stop editing' />
        </div>

        <RecordComposer
          message={recordMessage}
          onChange={setRecordMessage}
          onSave={doSave}
          onFilesAdded={addAttachments}
          attachments={attachments}
          removeAttachment={removeAttachment}
        />
      </div>
    </>
  )
}
