import { Link } from 'react-router-dom'

// eslint-disable-next-line arrow-body-style
export const HomePage = () => {
  return (
    <div>
      HomePage <Link to='/records'>Go to records</Link>
      <button
        type='button'
        onClick={() => {
          throw new Error('Error from Eugen on click')
        }}
      >
        Throw Error
      </button>
    </div>
  )
}
