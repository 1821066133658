import type { IFileData } from './IFileData'

export interface IAttachment {
  originName: string
  storedName: string
  mimeType: string
  url?: string
}

export const isAttachment = (attachment: IFileData | IAttachment): attachment is IAttachment =>
  Object.prototype.hasOwnProperty.call(attachment, 'originName') &&
  Object.prototype.hasOwnProperty.call(attachment, 'storedName') &&
  Object.prototype.hasOwnProperty.call(attachment, 'mimeType')
