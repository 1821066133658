import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import s from './DropDownMenu.module.scss'
import { noopFn } from '../../utils/noopFn'

interface IToggleMenuItemProps {
  toggleMenu?: () => void
  isOpen: boolean
}

export const ToggleMenuItem = ({ toggleMenu = noopFn, isOpen }: IToggleMenuItemProps) => (
  <div className={s.item}>
    <button
      type='button'
      aria-label='Toggle application menu'
      className='actionBtn'
      onClick={toggleMenu}
    >
      <FontAwesomeIcon icon={isOpen ? faXmark : faBars} />
    </button>
  </div>
)
