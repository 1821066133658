// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker.register(swUrl).then((registration) => {
    // eslint-disable-next-line no-param-reassign
    registration.onupdatefound = () => {
      const installingWorker = registration.installing
      if (installingWorker == null) {
        return
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // // At this point, the updated precached content has been fetched,
            // // but the previous service worker will still serve the older
            // // content until all client tabs are closed.
            // console.log(
            //   'New content is available and will be used when all ' +
            //     'tabs for this page are closed. See https://cra.link/PWA.'
            // )

            // TODO: Probably need to notify user about availability of newer version so he will decide
            // When to see update: immediately (need to care about other browser tabs with the app)
            // or later, once all tab with the app closed.

            // Execute callback
            if (config && config.onUpdate) {
              config.onUpdate(registration)
            }
          } else {
            // At this point, everything has been precached.

            // Execute callback
            // eslint-disable-next-line no-lonely-if
            if (config && config.onSuccess) {
              config.onSuccess(registration)
            }
          }
        }
      }
    }
  })
}

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

function register(config?: Config) {
  if (process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
      registerValidSW(swUrl, config)
    })
  }
}

function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}

export const serviceWorkerRegistration = {
  register,
  unregister,
}
