import { useCallback } from 'react'
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord'
import s from './RecordMenu.module.scss'
import { useAppStore } from '../../../../stores/appStore'

interface IRecordMenuProps {
  recordId: string
}

// TODO: Need to implement determining if there is enough space at the bottom to show the menu.
// If menu should be shown for the latest record - there is definitely not enough space.
export const RecordMenu = ({ recordId }: IRecordMenuProps) => {
  const doDelete = useDeleteRecord(recordId)
  const setIsEditing = useAppStore((state) => state.setRecordId)

  const startEditing = useCallback(() => {
    setIsEditing(recordId)
  }, [recordId, setIsEditing])

  return (
    <div className={s.menuContainer}>
      <button type='button' className={s.menuItem} onClick={startEditing}>
        Edit
      </button>
      <div className='horizontalLine' />
      <button type='button' className={s.menuItem} onClick={doDelete}>
        Delete
      </button>
    </div>
  )
}
