import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { useCallback } from 'react'
import s from './NotAuthorizedHome.module.scss'
import { useAppStore } from '../../stores/appStore'

export const NotAuthorizedHome = () => {
  const setIsLoading = useAppStore((state) => state.setIsLoading)

  const doSignInUp = useCallback(() => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
    })
    signInWithPopup(auth, provider)
    setIsLoading(true)
  }, [setIsLoading])

  return (
    <div className={s.container}>
      Please,{' '}
      <button type='button' className='actionBtn' onClick={doSignInUp}>
        SignIn
      </button>
      /
      <button type='button' className='actionBtn' onClick={doSignInUp}>
        SignUp
      </button>{' '}
      to use the app?
    </div>
  )
}
