import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { useCallback } from 'react'
import { getRecordsCollectionName } from '../shortcuts/getRecordsCollectionName'

export const useDeleteRecord = (docId: string) =>
  useCallback(() => {
    // TODO: Need to implement deletion of attachments.
    const db = getFirestore()
    return deleteDoc(doc(db, getRecordsCollectionName(), docId))
  }, [docId])
