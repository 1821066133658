import type { IAttachment } from './IAttachment'

export interface IFileData {
  fileName: string
  blob: Blob
}

export const isFileData = (fileData: IFileData | IAttachment): fileData is IFileData =>
  Object.prototype.hasOwnProperty.call(fileData, 'fileName') &&
  Object.prototype.hasOwnProperty.call(fileData, 'blob')
