import { useEffect } from 'react'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { useUserStore } from '../../stores/userStore'
import { useAppStore } from '../../stores/appStore'

interface IFirebaseProviderProps {
  config: FirebaseOptions
}

let isFirebaseInitiated = false

export const FirebaseProvider = ({ config }: IFirebaseProviderProps) => {
  const setUser = useUserStore((state) => state.setUser)
  const setIsLoading = useAppStore((state) => state.setIsLoading)
  const setBootstrapped = useAppStore((state) => state.setBootstrapped)

  useEffect(() => {
    // NOTE: Just to prevent re-initialization on HMR.
    if (process.env.NODE_ENV !== 'development' || !isFirebaseInitiated) {
      initializeApp(config)
      isFirebaseInitiated = true
    }

    if (process.env.NODE_ENV === 'development') {
      const auth = getAuth()
      connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })

      const db = getFirestore()
      connectFirestoreEmulator(db, '127.0.0.1', 8080)

      const storage = getStorage()
      connectStorageEmulator(storage, '127.0.0.1', 9199)
    }

    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setIsLoading(false)
      setBootstrapped()
    })

    return unsubscribe
  }, [config, setBootstrapped, setIsLoading, setUser])

  return null
}
