import classNames from 'classnames'
import s from './Header.module.scss'
import { DropDownMenu } from '../DropDownMenu/DropDownMenu'

export const Header = () => (
  <div className={classNames('recordWrapper', s.appHeader)}>
    <div className={s.headerText}>Hi, I am BackPack online!</div>
    <div className={s.headerActions}>
      <DropDownMenu />
    </div>
  </div>
)
