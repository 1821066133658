import { useCallback, useEffect, useState } from 'react'
import { useRollbar } from '@rollbar/react'
import s from './CreateRecord.module.scss'
import { IRecordAddData, IRecordUpdateData } from '../../interfaces/IRecord'
import { useSaveRecord } from '../../hooks/useSaveRecord'
import { RecordComposer } from '../RecordComposer/RecordComposer'
import { Info } from '../utils/Info/Info'
import { useNewRecordStore } from '../../stores/newRecordStore'
import { useMessage } from '../../hooks/useSharedText'
import { IFileData } from '../../interfaces/IFileData'
import { IAttachment, isAttachment } from '../../interfaces/IAttachment'

const DEFAULT_ERROR_MESSAGE = 'Some error appears during saving of note.'

export const CreateRecord = () => {
  const rollbar = useRollbar()

  const [errorMessage, setErrorMessage] = useState('')

  const [recordMessage, resetMessage] = useMessage()
  const setRecordMessage = useNewRecordStore((state) => state.setMessage)

  const loadAttachmentsFromCache = useNewRecordStore((state) => state.loadAttachmentsFromCache)
  const attachments = useNewRecordStore((state) => state.attachments)
  const addAttachments = useNewRecordStore((state) => state.addAttachments)
  const removeAttachment = useNewRecordStore((state) => state.removeAttachment)
  const clearAttachments = useNewRecordStore((state) => state.clearAttachments)

  useEffect(() => {
    setRecordMessage(recordMessage)
    loadAttachmentsFromCache()
  }, [loadAttachmentsFromCache, recordMessage, setRecordMessage])

  const closeError = useCallback(() => {
    setErrorMessage('')
  }, [])

  const addRecord = useSaveRecord()
  const doSave = useCallback(() => {
    if (!recordMessage && !attachments.length) {
      setErrorMessage('Saving of empty record is forbidden.')
      return
    }

    if (errorMessage) {
      setErrorMessage('')
    }

    const newRecord: IRecordAddData | IRecordUpdateData = {
      message: recordMessage,
    }

    addRecord(newRecord, attachments).then(
      () => {
        clearAttachments()
        resetMessage()
      },
      (err) => {
        rollbar.error(err)
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
      }
    )
  }, [recordMessage, attachments, errorMessage, addRecord, clearAttachments, resetMessage, rollbar])

  const removeAttachmentCallback = useCallback(
    (fileData: IFileData | IAttachment) => {
      if (isAttachment(fileData)) {
        throw new Error(
          'Attaching of already stored file while creating of record is not expected.'
        )
      }

      removeAttachment(fileData)
    },
    [removeAttachment]
  )

  return (
    <>
      {errorMessage && <Info message={errorMessage} onClose={closeError} type='error' isClosable />}

      <div className={s.editRecordContainer}>
        <RecordComposer
          message={recordMessage}
          onChange={setRecordMessage}
          onSave={doSave}
          onFilesAdded={addAttachments}
          attachments={attachments}
          removeAttachment={removeAttachmentCallback}
        />
      </div>
    </>
  )
}
