import { PropsWithChildren } from 'react'
import { useAppStore } from '../../../stores/appStore'
import { Info } from '../../utils/Info/Info'

export const CommonLayout = ({ children }: PropsWithChildren) => {
  const isBootstrapped = useAppStore((state) => state.isBootstrapped)
  const isOffline = useAppStore((state) => state.isOffline)

  if (!isBootstrapped) {
    return <div>Loading...</div>
  }

  return (
    <>
      {/* TODO: show message as overlay. */}
      {isOffline && <Info message='You are currently offline.' type='warn' />}

      {children}
    </>
  )
}
