import { FieldValue } from 'firebase/firestore'
import { IFileData } from './IFileData'
import { IAttachment } from './IAttachment'

// TODO: Cleanup these interfaces.

export interface IRecordUserData {
  message: string
  attachments?: IAttachment[]
}

export interface IRecordAddData extends IRecordUserData {}

export interface IRecordUpdateData extends IRecordUserData {
  id: string
}

export const isRecordUpdatable = (
  record: IRecordUserData | IRecordUpdateData
): record is IRecordUpdateData => Object.prototype.hasOwnProperty.call(record, 'id')

export interface IRecordData extends IRecordUserData {
  owner: string
  createdTimestamp: FieldValue
  updatedTimestamp: FieldValue
}

export interface IRecord extends IRecordData, IRecordUpdateData {}

export interface INewRecord {
  message: string
  attachments: IFileData[]
}
