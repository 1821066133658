import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNewRecordStore } from '../stores/newRecordStore'

export const useMessage = (): [string, () => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const message = useNewRecordStore((state) => state.message)
  const setRecordMessage = useNewRecordStore((state) => state.setMessage)
  const [hasSharingMessage, setHasSharingMessage] = useState(false)

  useEffect(() => {
    const chunks = [
      searchParams.get('title'),
      searchParams.get('text'),
      searchParams.get('url'),
    ].filter(Boolean)

    if (chunks.length) {
      setRecordMessage(chunks.filter(Boolean).join('\n'))
      setHasSharingMessage(true)
    } else {
      setHasSharingMessage(false)
    }
  }, [searchParams, setRecordMessage])

  const resetMessage = useCallback(() => {
    if (hasSharingMessage) {
      setSearchParams(undefined)
    } else {
      setRecordMessage('')
    }
  }, [hasSharingMessage, setRecordMessage, setSearchParams])

  return [message, resetMessage]
}
