import { IAttachment, isAttachment } from '../../interfaces/IAttachment'
import { IFileData } from '../../interfaces/IFileData'
import { noopFn } from '../../utils/noopFn'
import { Attachment } from '../Attachment/Attachment'
import s from './Attachments.module.scss'

interface IAttachmentsProps {
  filesData?: (IFileData | IAttachment)[]
  removeAttachment?: (fileData: IFileData | IAttachment) => void
}

// eslint-disable-next-line arrow-body-style
export const Attachments = ({ filesData = [], removeAttachment = noopFn }: IAttachmentsProps) => {
  return (
    <div className={s.filesContainer}>
      {filesData.map((fileData) => (
        // TODO: Origin file name can be repeated, so need to do something to prevent duplicating of key value.
        <Attachment
          key={isAttachment(fileData) ? fileData.storedName : fileData.fileName}
          fileData={fileData}
          removeAttachment={removeAttachment}
        />
      ))}
    </div>
  )
}
