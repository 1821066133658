import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'
import { GoogleAuthProvider, getAuth, signInWithRedirect, signOut } from 'firebase/auth'
import { useUserStore } from '../../stores/userStore'
import s from './DropDownMenu.module.scss'
import { useAppStore } from '../../stores/appStore'

export const SignItem = () => {
  const user = useUserStore((state) => state.user)
  const setIsLoading = useAppStore((state) => state.setIsLoading)

  const doSignAction = useCallback(() => {
    const auth = getAuth()
    if (user) {
      signOut(auth)
      // TODO: Clear caches before signOut.
    } else {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account',
      })
      signInWithRedirect(auth, provider)
    }
    setIsLoading(true)
  }, [setIsLoading, user])

  return (
    <div className={s.item}>
      <button
        type='button'
        aria-label={user ? 'Sign Out' : 'Sign In/Out'}
        className='actionBtn'
        onClick={doSignAction}
      >
        <FontAwesomeIcon icon={user ? faArrowRightFromBracket : faArrowRightToBracket} />
      </button>
    </div>
  )
}
