import classNames from 'classnames'
import { RouterProvider } from 'react-router-dom'
import s from './App.module.scss'
import { router } from '../../router'
import { Loading } from '../Loading/Loading'

export const App = () => (
  <div className={classNames(s.app)}>
    <RouterProvider router={router} />
    <Loading />
  </div>
)
