import { createBrowserRouter } from 'react-router-dom'
import { RecordsPage } from './components/pages/RecordsPage/RecordsPage'
import { HomePage } from './components/pages/HomePage/HomePage'
import { CommonLayout } from './components/layouts/CommonLayout/CommonLayout'
import { AuthenticatedLayout } from './components/layouts/AuthenticatedLayout/AuthenticatedLayout'
import { SettingsPage } from './components/pages/SettingsPage/SettingsPage'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <CommonLayout>
        <HomePage />
      </CommonLayout>
    ),
  },
  {
    path: '/records',
    element: (
      <CommonLayout>
        <AuthenticatedLayout>
          <RecordsPage />
        </AuthenticatedLayout>
      </CommonLayout>
    ),
  },
  {
    path: '/settings',
    element: (
      <CommonLayout>
        <AuthenticatedLayout>
          <SettingsPage />
        </AuthenticatedLayout>
      </CommonLayout>
    ),
  },
])
