import ReactDOM from 'react-dom/client'
import { ErrorBoundary, Provider } from '@rollbar/react'
import './styles/index.scss'
import { App } from './components/App/App'
import { reportWebVitals } from './reportWebVitals'
import { FirebaseProvider } from './components/FirebaseProvider/FirebaseProvider'
import { firebaseConfig } from './firebaseConfig'
import { rollbarConfig } from './rollbarConfig'
import { serviceWorkerRegistration } from './sw/serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider config={rollbarConfig}>
    <FirebaseProvider config={firebaseConfig} />
    {/* <React.StrictMode> */}
    {/* <ErrorBoundary fallbackUI={FallbackUI} > */}
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
    {/* </React.StrictMode> */}
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
