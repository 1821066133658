import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ReactNode } from 'react'
import classNames from 'classnames'
import s from './Info.module.scss'
import { IconBtn } from '../IconBtn/IconBtn'

interface IInfoBaseProps {
  message: ReactNode
  isClosable?: boolean
  type?: 'error' | 'warn'
}

interface IInfoNotClosableProps extends IInfoBaseProps {
  isClosable?: false
  onClose?: never
}

interface IInfoClosableProps extends IInfoBaseProps {
  isClosable?: true
  onClose?: () => void
}

export const Info = ({
  message,
  isClosable = false,
  onClose,
  type = 'error',
}: IInfoClosableProps | IInfoNotClosableProps) => (
  <div className={classNames(s.infoWrapper, s[type])}>
    <span>{message}</span>
    {isClosable && <IconBtn icon={faXmark} onClick={onClose} aria-label='Hide error message' />}
  </div>
)
