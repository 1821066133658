import { FieldValue, serverTimestamp, updateDoc } from 'firebase/firestore'
import { IRecordUpdateData } from '../interfaces/IRecord'
import { getRecordsRef } from './getRecordRef'
import { IAttachment } from '../interfaces/IAttachment'

// NOTE: Somehow the TS warns when used interface instead of type (with the same definition of fields).
type TRecordDataToUpdate = {
  message: string
  updatedTimestamp: FieldValue
  attachments: IAttachment[]
}

export const updateRecord = (data: IRecordUpdateData) => {
  const recordDataToUpdate: TRecordDataToUpdate = {
    message: data.message,
    attachments: data.attachments || [],
    updatedTimestamp: serverTimestamp(),
  }

  return updateDoc(getRecordsRef(data.id), recordDataToUpdate)
}
