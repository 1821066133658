import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { noopFn } from '../../../utils/noopFn'
import s from './IconBtn.module.scss'

interface IIconBtnProps {
  onClick?: typeof noopFn
  'aria-label': string
  icon: IconDefinition
  className?: string
}

export const IconBtn = ({
  onClick = noopFn,
  'aria-label': araLabel,
  icon,
  className = '',
}: IIconBtnProps) => (
  <button
    type='button'
    onClick={onClick}
    aria-label={araLabel}
    className={classNames(s.clearBtn, className)}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)
