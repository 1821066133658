import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import s from './Loading.module.scss'
import { useAppStore } from '../../stores/appStore'

export const Loading = () => {
  const isLoading = useAppStore((state) => state.isLoading)

  return isLoading ? (
    <div className={s.loading}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  ) : null
}
