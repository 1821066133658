import { PropsWithChildren } from 'react'
import { useUserStore } from '../../../stores/userStore'
import { NotAuthorizedHome } from '../../NotAuthorizedHome/NotAuthorizedHome'

export const AuthenticatedLayout = ({ children }: PropsWithChildren) => {
  const user = useUserStore((state) => state.user)

  // TODO: Change NotAuthorizedHome to proper view when user not authorized. Probably redirect to home page or show notification, or mixed both functionality.
  return user ? children : <NotAuthorizedHome />
}
