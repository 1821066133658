import { RefObject, useEffect } from 'react'

export const useAutoFocus = (inputRef: RefObject<HTMLTextAreaElement>) => {
  useEffect(() => {
    if (!inputRef.current) return

    inputRef.current.focus()

    const position = inputRef.current.value.length
    inputRef.current.setSelectionRange(position, position)
    inputRef.current.scrollTo(0, inputRef.current.scrollHeight)
  }, [inputRef])
}
